.swap-interface {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.input-container {
  width: 100%;
  margin-bottom: 10px;
}

.amount-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.swap-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #3b5998;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.swap-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
