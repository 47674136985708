.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 80%;
  width: 300px;
}

.modal-message {
  margin-bottom: 15px; /* Adjust as needed */
}

.modal-close {
  margin-top: 15px; /* Increase this value for more spacing */
  padding: 8px 16px;
  background-color: #4a4a8f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-close:hover {
  background-color: #3a3a7f;
}

.modal-ok-button {
  padding: 6px 12px; /* Adjust as needed */
  font-size: 14px;
  background-color: #4a4a8f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-ok-button:hover {
  background-color: #3a3a7f;
}
