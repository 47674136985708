/* Dashboard container styling */
.dashboard-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 600px;
  margin: 40px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Proc', sans-serif; /* You can customize the font if needed */
}

/* Heading styling */
.dashboard-heading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Error message styling */
.dashboard-error {
  color: red;
  margin-bottom: 15px;
}

/* User data styling */
.dashboard-user-data {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.dashboard-user-data p {
  margin: 8px 0; /* Space between user data items */
}
