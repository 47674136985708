.credit-call-container {
  padding: 40px; /* Increase padding for a larger container */
  background-color: #f9f9f9;
  border-radius: 10px; /* Slightly increase border radius for a smoother appearance */
  max-width: 800px; /* Increase max-width to make the form wider */
  margin: 40px auto; /* Increase the margin for better spacing */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Slightly larger shadow for a more pronounced effect */
  font-family: 'Proc', sans-serif;
}

.credit-call-heading {
  font-size: 28px; /* Increase the font size for the heading */
  font-weight: bold;
  color: #333;
  margin-bottom: 15px; /* Increase the margin below the heading */
}

.credit-call-form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Increase the gap between form elements */
}

.credit-call-label {
  font-size: 16px; /* Slightly larger label font size */
  color: #555;
}

.credit-call-input {
  padding: 12px; /* Increase padding for a larger input field */
  border: 1px solid #ccc;
  border-radius: 5px; /* Slightly larger border radius */
  font-size: 18px; /* Increase font size for input text */
}

.credit-call-button {
  padding: 12px 20px; /* Increase button padding */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px; /* Slightly larger border radius */
  cursor: pointer;
  font-size: 16px; /* Increase font size for the button */
}

.credit-call-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.credit-call-result {
  margin-top: 30px; /* Increase margin for better spacing */
}

.credit-call-result-heading {
  font-size: 22px; /* Increase font size for result heading */
  color: #333;
  margin-bottom: 15px; /* Increase margin below the heading */
}

.credit-call-result-content {
  font-size: 16px; /* Increase font size for result content */
  color: #555;
  line-height: 1.6; /* Increase line height for better readability */
}

.credit-call-modal-heading {
  font-size: 20px; /* Increase modal heading font size */
  color: #333;
}

.credit-call-modal-content {
  font-size: 16px; /* Increase modal content font size */
  color: #555;
}
