/* Import your custom font */
@font-face {
  font-family: 'Rale';
  src: url('./fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RB';
  src: url('./fonts/OrbitronBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proc';
  src: url('./fonts/Proc.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Box sizing for consistent layout */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Base styling */
body {
  margin-top: 0; /* Adjust this value based on the height of your Navbar */
  min-width: 320px;
  min-height: 100vh;
  background-image: url('./back6.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Rale', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-wrapper {
    max-width: 100%;
    padding: 20px;
    padding-top: 80px; /* Add padding-top to offset the navbar height */
    margin: 0 auto;
}

@media (max-width: 768px) {
    .content-wrapper {
        padding-top: 70px; /* Smaller padding for mobile */
    }
}

@media (max-width: 480px) {
    .content-wrapper {
        padding: 10px;
    }
}

/* Branding */
.finanon-brand {
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 20px;
  color: white;
  font-family: 'Rale', sans-serif;
  z-index: 1000;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  body {
    background-size: cover;
    background-position: center;
    margin-top: 0;
    font-size: 14px;
  }

  .finanon-brand {
    font-size: 18px;
    top: 15px;
  }

  /* Navbar adjustments */
  .navbar {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .navbar-item {
      padding: 10px;
      text-align: center;
  }
}

/* Tablet adjustments */
@media (min-width: 768px) and (max-width: 1199px) {
  body {
    background-size: auto 600px;
    background-attachment: scroll;
  }

  .finanon-brand {
    font-size: 22px;
    top: 40px;
  }
}

/* Desktop adjustments */
@media (min-width: 1200px) {
  body {
    background-size: cover;
    background-attachment: fixed;
  }

  .finanon-brand {
    font-size: 24px;
    top: 60px;
  }
}
