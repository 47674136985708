/* Container for centering the content */
.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    padding-top: 80px; /* Match this to the navbar height */
    text-align: center;
    box-sizing: border-box;
}

/* Style for the centered title */
.landing-title {
  font-size: 5vw; /* Use viewport width for responsiveness */
  font-family: 'Proc', sans-serif;
  margin-bottom: 20px;
  color: white;
}

/* Style for the introductory text */
.landing-text {
  font-size: 1.5em; /* Relative font size */
  font-family: 'Proc', sans-serif;
  text-align: center;
  color: white;
  width: 80%;
  max-width: 600px;
  margin-bottom: 20px;
}

/* Style for the second title */
.secondlanding-title {
  font-size: 3vw;
  font-family: 'Proc', sans-serif;
  margin-bottom: 10px;
  color: white;
}

/* New bubble-style container */
.bubble-container {
  background-color: rgba(0, 0, 0, 0.05);
  color: black;
  padding: 10px;
  border-radius: 10px;
  width: 90%; /* Adjust to fit mobile screens */
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Style for the text inside the bubble */
.secondlanding-text,
.thirdlanding-text {
  font-size: 1.5em; /* Use relative units for font size */
  font-family: 'Proc', sans-serif;
  margin-bottom: 10px;
  color: white;
}

/* Stripe Payment Section */
.stripe-payment-section,
.credit-call-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .landing-title {
    font-size: 8vw;
  }

  .landing-text,
  .secondlanding-text,
  .thirdlanding-text {
    font-size: 1.2em;
  }

  .secondlanding-title {
    font-size: 6vw;
  }

  .bubble-container {
    width: 100%; /* Use full width on mobile screens */
    padding: 15px;
    margin: 0 auto;
  }

  .landing-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
