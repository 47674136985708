/* Container for the Behind the Analysis section */
.behind-analysis-container {
  background-color: rgba(0, 0, 0, 0.1); /* Subtle contrast against existing background */
  padding: 20px;
  border-radius: 10px;
  width: 90%; /* Fit within the layout's width */
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow for emphasis */
  text-align: left; /* Align text to the left for a professional look */
}

/* Title styling for the section */
.behind-analysis-title {
  font-size: 2.5vw; /* Scales with screen size */
  font-family: 'Proc', sans-serif;
  color: white; /* Matches existing theme */
  margin-bottom: 15px;
  text-align: center; /* Center the title */
}

/* Text description styling */
.behind-analysis-text {
  font-size: 1.2em; /* Slightly smaller than the main title */
  font-family: 'Proc', sans-serif;
  color: white; /* Consistent with the theme */
  margin-bottom: 15px;
  line-height: 1.5; /* Improve readability */
}

/* List styling for key points */
.behind-analysis-list {
  list-style: disc; /* Use a clean disc bullet style */
  margin-left: 20px;
  color: white; /* Matches the theme */
  font-family: 'Proc', sans-serif;
}

.behind-analysis-list li {
  margin-bottom: 10px;
  font-size: 1em; /* Consistent with other text sizes */
  line-height: 1.4; /* Maintain readability */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .behind-analysis-container {
    width: 100%; /* Full width for smaller screens */
    padding: 15px;
    margin: 10px auto;
  }

  .behind-analysis-title {
    font-size: 4vw; /* Adjust title size for mobile */
  }

  .behind-analysis-text {
    font-size: 1em; /* Adjust text size for mobile */
  }

  .behind-analysis-list li {
    font-size: 0.9em; /* Slightly smaller on mobile */
  }
}
