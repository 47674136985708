/* Base styling for Stripe Payment container */
.stripe-payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: black;
  padding: 40px;
  border-radius: 10px;
  max-width: 800px; /* Increased width for larger display */
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Proc', sans-serif;
}

/* Heading adjustments */
.stripe-payment-container h2 {
  color: black;
}

/* Package options styling */
.package-options {
  display: flex;
  justify-content: space-around; /* Even spacing */
  gap: 20px;
  margin: 20px 0;
}

/* Each package card styling */
.package {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  width: 150px;
  cursor: pointer;
  transition: transform 0.2s;
  background-color: #f9f9f9;
}

/* Hover effect for package */
.package:hover {
  transform: scale(1.05);
}

/* Adjustments for heading and paragraph text */
.stripe-payment-container .package h3,
.stripe-payment-container .package p {
  color: black;
}

/* Button styling */
.stripe-payment-container button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: black !important;
  color: white !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Proc', sans-serif;
}

.stripe-payment-container button:hover {
  background-color: #333333 !important;
}

/* Package description styling */
.package-description {
  font-size: 16px;
  color: black;
  margin-bottom: 20px;
  text-align: center;
}

/* Media query adjustments for mobile screens */
@media (max-width: 768px) {
  .stripe-payment-container {
    padding: 20px;
    max-width: 90%;
  }

  .package-options {
    flex-direction: column; /* Stack packages vertically on mobile */
    align-items: center;
  }

  .package {
    width: 100%; /* Full width for each package on mobile */
    margin-bottom: 10px;
  }

  .package-description {
    font-size: 14px; /* Slightly smaller font size for readability */
  }
}
