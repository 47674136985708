:root {
  --curve-blue: #3b5998;
  --light-gray: #d3d3d3;
  --background-black: #000000;
  --white: #ffffff;
}

body {
  background-color: var(--background-black);
  font-family: 'Courier New', Courier, monospace;
  color: #333;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  padding: 20px;
}

.App-header h1 {
  display: none; /* Hide the text header since we will use the image */
}

.App-header img {
  width: 200px;
  margin: 10px;
}

.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-connection {
  background-color: var(--white);
  color: black;
  width: 100%;
  max-width: 600px;
  padding: 10px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #a9a9a9;
}

.wallet-connection span {
  font-size: 14px;
}

.interaction-container {
  background-color: var(--white);
  color: black;
  padding: 20px;
  width: 100%;
  max-width: 600px;
}

.footer-container {
  background-color: var(--white);
  color: black;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

/* Styling for all buttons */
button,
.wallet-adapter-button {
  background-color: var(--background-black) !important;
  color: var(--white) !important;
  border: none !important;
  padding: 10px 20px !important;
  margin: 5px 0 !important;
  font-size: 14px !important;
  cursor: pointer !important;
  font-family: 'Proc', Courier, monospace !important;
}

button:hover,
.wallet-adapter-button:hover {
  background-color: #333333 !important;
}

h2 {
  color: var(--background-black);
}

.subtext {
  color: #333;
}

.reload-button {
  margin-top: 20px;
}

/* Specific styling for the input field */
input[type="number"] {
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  max-width: 200px;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .App-header img {
    width: 150px;
  }

  .wallet-connection {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .wallet-connection span {
    font-size: 12px;
  }

  .interaction-container {
    padding: 15px;
  }

  .footer-container {
    padding: 15px;
  }

  button,
  .wallet-adapter-button {
    width: 100%;
    max-width: 300px;
    font-size: 12px !important;
    padding: 10px !important;
  }

  .reload-button {
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
  }

  input[type="number"] {
    width: 100%;
    max-width: 100%;
  }
}
